import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useForm
} from 'react-hook-form';
import GooglePlacesAutocomplete from 'src/components/core/molecules/GooglePlacesAutocomplete';

import { ChevronDown, HelpCircle, MapPin, X } from 'lucide-react';
import { Fragment, useState } from 'react';
import {
  CanadianProvincesEnum,
  CountryNamesEnum,
  EmissionFactorFragmentFragment,
  UsStatesEnum
} from 'src/__apolloGenerated__/graphql';
import Skeleton from 'src/components/core/atoms/Skeleton';
import TooltipIcon from 'src/components/core/atoms/TooltipIcon';
import SingleSelect, {
  OptionType
} from 'src/components/core/molecules/SingleSelect';
import {
  BranchIdentifierQuestion,
  DatePickerQuestion,
  EmbeddedSingleSelect,
  EmissionFactorQuestion,
  GhgCategoryQuestion,
  MultiSelectQuestion,
  RadioGroupQuestion,
  SingleSelectQuestion,
  StrategyIdentifierQuestion,
  ValueUnitQuestion
} from 'src/components/core/organisms/Form/question-types';
import { Button } from 'src/components/shad-base/button';
import { Input } from 'src/components/shad-base/input';
import { Switch } from 'src/components/shad-base/switch';
import { Textarea } from 'src/components/shad-base/textarea';
import {
  COUNTRY_CAPITALS,
  COUNTRY_REGIONS,
  COUNTRY_REGIONS_NAME,
  COUNTRY_REGIONS_TYPE
} from 'src/utils/country';
import { fEnum, getListItemKey } from 'src/utils/format';
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng
} from 'use-places-autocomplete';
import * as Yup from 'yup';

export type FormQuestionTypeType =
  | 'text'
  | 'textarea'
  | 'number'
  | 'multi-select'
  | 'single-select'
  | 'date'
  // Address is a special type that generates multiple sub-fields
  // (street, city, state, zip, country, etc.)
  // No need to define these fields in the questions array
  | 'address'
  | 'radio-group'
  | 'boolean'
  | 'icon'
  | 'custom'
  | 'value-unit'
  | 'ghg-category'
  | 'emission-factor'
  | 'branch-identifier'
  | 'strategy-identifier'
  | 'follow-up';

export type FormValueType =
  | string
  | number
  | string[]
  | Date
  | null
  | boolean
  | EmissionFactorFragmentFragment
  | {
      street: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      apt?: string;
    };

function ValidateQuestionProps(question: FormQuestionType) {
  if (question.type === 'custom' && !question.renderCustomQuestion) {
    throw new Error(
      `Error in form: Question with key ${question.key} has type 'custom' but no renderCustomQuestion function was provided.`
    );
  }
  // if (
  //   question.type === 'custom' &&
  //   question.required &&
  //   question.getErrorMessage === undefined
  // ) {
  //   throw new Error(
  //     `Error in form: Question with key ${question.key} is custom and required but no getErrorMessage function was provided.`
  //   );
  // }
  if (question.dependsOnQuestionKey && !question.getQuestion) {
    throw new Error(
      `Error in form: Question with key ${question.key} has dependsOnQuestionKey but no getQuestion function was provided.`
    );
  }
  if (
    (question.type == 'single-select' ||
      question.type == 'multi-select') &&
    !question.options
  ) {
    if (!question.dependsOnQuestionKey) {
      throw new Error(
        `Error in form: Question with key ${question.key} has type ${question.type} but no options were provided.`
      );
    }
  }
}

export type FormQuestionType = {
  key: string;
  type: FormQuestionTypeType;
  text: string;
  defaultValue: FormValueType;
  required: boolean;
  options?: OptionType[];
  description?: string;
  errorMessage?: string;
  helperMessage?: string;
  placeholder?: string;
  embeddedSingleSelect?: {
    key: string;
    defaultValue: string | number;
    options: OptionType[];
    helperText?: string;
  };

  // If true, question will only render if the question with this key has been answered
  dependsOnQuestionKey?: string;
  dependsOnQuestionValue?: string;

  // Returns question text and options based on the answers to parent question
  // Only necessary if dependsOnQuestionKey is not null
  getQuestion?: (formValues: { [key: string]: FormValueType }) => {
    text: string;
    options?: OptionType[];
  };

  // If question type is 'custom', these functions are required
  renderCustomQuestion?: ({
    field: { onChange, name, onBlur, value, ref }
  }) => JSX.Element;
  getErrorMessage?: (formValues: {
    [key: string]: FormValueType;
  }) => string;
};

export type QuestionRowType = {
  questions: FormQuestionType[];
};

export default function Form({
  rows,
  onSubmit,
  isSubmitting,
  submitButtonText,
  submitToggleText,
  setLatLng,
  submitButtonDisabled
}: {
  rows: QuestionRowType[];
  onSubmit: (
    values,
    submitToggleChecked: boolean,
    resetFormValues: () => void
  ) => void;
  isSubmitting: boolean;
  setLatLng?: (latlng: { lat: number; lng: number }) => void;
  submitButtonText?: string;
  submitToggleText?: string;
  submitButtonDisabled?: boolean;
}) {
  const [submitToggleChecked, setSubmitToggleChecked] =
    useState(false);

  const allQuestions = [];
  rows.forEach((row) => {
    allQuestions.push(...row.questions);
  });

  const googlePlacesHookReturn = usePlacesAutocomplete();

  // Determine if a question should be rendered based on its dependencies
  const getShouldRenderQuestion = (question) => {
    let shouldRender = true;
    if (question.dependsOnQuestionKey) {
      // If question is a follow-up, check if parent has been answered
      const parentQuestion = allQuestions.find(
        (q) => q.key === question.dependsOnQuestionKey
      );
      // If question has a dependsOnQuestionValue, check if it matches
      if (question.dependsOnQuestionValue) {
        shouldRender =
          formValues?.[question.dependsOnQuestionKey] ===
          question.dependsOnQuestionValue;
      } else if (parentQuestion.type === 'multi-select') {
        // If question is a multi-select, check if it has any values
        shouldRender =
          formValues?.[question.dependsOnQuestionKey]?.length > 0;
      } else {
        // If question is not a multi-select, check if it has been answered
        shouldRender = Boolean(
          formValues?.[question.dependsOnQuestionKey]
        );
      }
    }
    return shouldRender;
  };

  const getYupResolver = (question: FormQuestionType) => {
    if (!question) {
      return null;
    }
    let baseType = null;
    switch (question.type) {
      case 'text':
      case 'textarea':
        baseType = Yup.string().nullable();
        break;
      case 'number':
        baseType = Yup.number()
          .nullable()
          .typeError('Must be a number')
          .min(0, 'Must be greater than or equal to 0');
        break;
      case 'multi-select':
        if (question.required) {
          baseType = Yup.array().min(
            1,
            question.errorMessage || 'Must select at least one option'
          );
        } else {
          baseType = Yup.array();
        }
        break;
      case 'single-select':
        baseType = Yup.string().nullable();
        break;
      case 'date':
        baseType = Yup.date().nullable();
        break;
      case 'boolean':
        baseType = Yup.boolean().nullable();
        break;
      case 'emission-factor':
        baseType = Yup.object().nullable();
        break;
      case 'value-unit':
        baseType = Yup.string().nullable();
        break;
      case 'ghg-category':
        baseType = Yup.string().nullable();
        break;
      case 'branch-identifier':
        baseType = Yup.string().nullable();
        break;
      case 'strategy-identifier':
        baseType = Yup.string().nullable();
        break;
      default:
        baseType = Yup.string();
    }

    // If question is required, add error message, unless it's a custom question
    // Custom questions should do their own error validation
    if (
      question.required &&
      baseType &&
      question.type !== 'custom' &&
      getShouldRenderQuestion(question)
    ) {
      baseType = baseType.required(
        question.errorMessage || 'This field is required'
      );
    }
    return baseType;
  };

  const defaultValues = allQuestions.reduce(
    (defaultValues, question) => {
      if (question.embeddedSingleSelect) {
        // Initialize default value for embedded single select question
        defaultValues[question.embeddedSingleSelect.key] =
          question.embeddedSingleSelect.defaultValue;
      }

      if (question.type === 'address') {
        // Initialize default values for address fields (city, street, country, etc.)
        Object.keys(question.defaultValue || {}).map((key) => {
          defaultValues[key] = question.defaultValue[key] || '';
        });
      } else {
        // Set default value for question
        defaultValues[question.key] = question.defaultValue;
      }
      return defaultValues;
    },
    {}
  );

  // console.log('defaultValues', defaultValues);

  const formResolver = {};
  allQuestions.forEach((question) => {
    // Add base question to schema
    formResolver[question.key] = getYupResolver(question);

    if (question.embeddedSingleSelect) {
      const embeddedSingleSelect = question.embeddedSingleSelect;
      // Add embedded single select question to schema
      formResolver[embeddedSingleSelect.key] = Yup.string().required(
        embeddedSingleSelect.helperText || 'Must select an option'
      );
    }

    if (question.type === 'address') {
      // Add extra address fields to schema
      formResolver['street'] = Yup.string().required(
        'Street is required'
      );
      formResolver['city'] = Yup.string().required(
        'City is required'
      );
      formResolver['state'] = Yup.string().required(
        'This field is required'
      );
      formResolver['zip'] = Yup.string().required('Zip is required');
      formResolver['country'] = Yup.string().required(
        'Country is required'
      );
    }
  });

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    reset,
    formState: { errors, isValid }
  } = useForm({
    defaultValues,
    resolver: yupResolver(Yup.object().shape(formResolver))
  });

  function AddressInputQuestion({
    text,
    required,
    errorMessage,
    questionKey,
    ...inputProps
  }) {
    return (
      <div className="flex flex-col flex-nowrap">
        <div className="flex flex-nowrap">
          <p className="pb-sm">
            {text}
            {required && <span className=" text-orange-500"> *</span>}
          </p>
        </div>
        <div>
          <div className="flex w-full flex-col flex-nowrap">
            <Input
              {...register(questionKey)}
              {...inputProps}
              className="border"
            />
            {errorMessage && (
              <p className="body2 mt-1 text-destructive">
                {errorMessage}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  function AddressSingleSelectQuestion({
    text,
    required,
    errorMessage,
    questionKey,
    setFormValue,
    selectOptionsProp = []
    // placeholder
  }: {
    text: string;
    required: boolean;
    errorMessage:
      | string
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<unknown>>;
    questionKey: 'country' | 'state';
    setFormValue: (key: string, value: string) => void;
    selectOptionsProp?: {
      key: string;
      label: string;
    }[];
    // placeholder?: string;
  }) {
    return (
      <div className="flex flex-col flex-nowrap">
        <div className="flex flex-nowrap">
          <p className="pb-sm">
            {text}
            {required && <span className=" text-orange-500"> *</span>}
          </p>
        </div>
        <div>
          <div className="flex w-full flex-col flex-nowrap">
            <Controller
              control={control}
              name={questionKey}
              render={({
                field: { onChange, onBlur, value, ref }
              }) => {
                // ? [
                //     {
                //       key: CountryNamesEnum.UnitedStates,
                //       label: 'United States'
                //     },
                //     {
                //       key: CountryNamesEnum.Canada,
                //       label: 'Canada'
                //     }
                //   ]
                // ? Object.values(
                //     formValues?.['country'] ==
                //       CountryNamesEnum.Canada
                //       ? CanadianProvincesEnum
                //       : UsStatesEnum
                //   ).map((value) => ({
                //     key: value,
                //     label: fEnum(value)
                //   }))
                const selectOptions =
                  questionKey == 'country'
                    ? Object.values(CountryNamesEnum).map(
                        (country) => ({
                          key: country,
                          label: fEnum(country)
                        })
                      )
                    : formValues?.['country'] &&
                        formValues?.['country'] in COUNTRY_REGIONS
                      ? Object.entries(
                          COUNTRY_REGIONS[
                            formValues?.['country']
                          ] as COUNTRY_REGIONS_TYPE[keyof COUNTRY_REGIONS_TYPE]
                        ).map(([key, value]) => ({
                          key: key,
                          label: value
                        }))
                      : selectOptionsProp;

                return (
                  <SingleSelect
                    options={selectOptions}
                    value={value}
                    setValue={(value) => {
                      onChange(value);

                      // Reset state if country changes
                      if (questionKey == 'country') {
                        setFormValue('state', '');
                        setFormValue('city', '');
                        setFormValue('street', '');
                        setFormValue('apt', '');
                        setFormValue('zip', '');
                      }
                    }}
                    renderCustomTrigger={() => {
                      const selected = selectOptions.find(
                        (option) => option.key === value
                      );
                      return (
                        <div
                          ref={ref}
                          onBlur={onBlur}
                          className="flex h-10 cursor-pointer flex-nowrap items-center rounded-md border hover:bg-accent"
                        >
                          <p
                            className={
                              'w-full truncate text-nowrap pl-3 ' +
                              (selected ? '' : 'text-muted')
                            }
                          >
                            {selected
                              ? selected.label
                              : 'Select an option...'}
                          </p>
                          {selected && (
                            <div className="flex items-center">
                              <Button
                                variant="outline"
                                size="icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onChange('');
                                }}
                              >
                                <X className="h-icon w-icon" />
                              </Button>
                            </div>
                          )}
                          <ChevronDown className="mx-sm ml-sm h-5 w-5" />
                        </div>
                      );
                    }}
                  />
                );
              }}
            />
            {errorMessage && (
              <p className="body2 mt-1 text-destructive">
                {errorMessage as React.ReactNode}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  function getCountryEnumValue(country: string) {
    const formattedStr = country.toUpperCase().replace(' ', '_');
    const enumValue = Object.values(CountryNamesEnum).find(
      (value) => value === formattedStr
    );
    return enumValue;
  }

  const questionsToWatch = allQuestions
    .filter((question) => question.dependsOnQuestionKey)
    .map((question) => question.dependsOnQuestionKey);

  // for performance reasons, react-hook-form doesn't rerender when form values change
  // so we need to define the questionKeys that we want to "watch"
  // i.e. questions that prompt the render of follow-up questions
  watch(questionsToWatch);

  // Func to programmatically set form values
  const setFormValue = (key: string, value: string) => {
    setValue(key, value, { shouldValidate: true });
  };

  const formValues = getValues();
  const [iconIndices, setIconIndices] = useState<number[]>([]);
  const [selectRegionOptions, setSelectRegionOptions] = useState<
    {
      key: string;
      label: string;
    }[]
  >([]);

  // Handle submit and pass in props
  const handleSubmitWithProps = (values) => {
    onSubmit(values, submitToggleChecked, reset);
  };

  // Determine if a row should be rendered based on its questions
  const getShouldRenderRow = (row) => {
    return row.questions.every((question) =>
      getShouldRenderQuestion(question)
    );
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitWithProps)}>
      <div className="flex flex-col flex-nowrap">
        {rows.map((row, rowIdx) => {
          const shouldRenderRow = getShouldRenderRow(row);
          return (
            shouldRenderRow && (
              <div
                key={getListItemKey(rowIdx)}
                className={'w-full ' + (rowIdx !== 0 && 'mt-lg')}
              >
                <div className="flex w-full flex-col md:flex-row">
                  {row.questions.map((question, questionIdx) => {
                    // Throw detailed errors if question props are invalid
                    ValidateQuestionProps(question);

                    const embeddedSingleSelect =
                      question.embeddedSingleSelect;

                    const shouldRenderQuestion =
                      getShouldRenderQuestion(question);

                    // Get question text and options based on type of question (follow-up or not)
                    const text = question.dependsOnQuestionKey
                      ? question.getQuestion(formValues)?.text
                      : question.text;

                    const options = question.dependsOnQuestionKey
                      ? question.getQuestion(formValues)?.options
                      : question.options;

                    const getAddressEnumValue = (
                      addressComponent: string,
                      type: 'country' | 'state' | 'province'
                    ) => {
                      const formattedStr = addressComponent
                        .toUpperCase()
                        .replace(' ', '_');
                      const enumValue = Object.values(
                        type == 'country'
                          ? CountryNamesEnum
                          : type == 'state'
                            ? UsStatesEnum
                            : type == 'province'
                              ? CanadianProvincesEnum
                              : {}
                      ).find((value) => value === formattedStr);
                      return enumValue;
                    };

                    return (
                      shouldRenderQuestion && (
                        <div
                          key={question.key}
                          className={
                            'w-full ' + (questionIdx !== 0 && 'ml-sm')
                          }
                        >
                          <div className="flex flex-col flex-nowrap">
                            {/* Question Text */}
                            {question.type !== 'custom' && (
                              <div className="mb-sm">
                                <div className="flex flex-nowrap items-center">
                                  <p>
                                    {text}
                                    {question.required && (
                                      <span className=" text-destructive">
                                        {' '}
                                        *
                                      </span>
                                    )}
                                  </p>
                                  {question.helperMessage && (
                                    <div className="ml-sm">
                                      <TooltipIcon
                                        text={question.helperMessage}
                                        trigger={
                                          <HelpCircle className="h-5 w-5 text-muted" />
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {question.description && (
                              <p className="mb-sm text-muted">
                                {question.description}
                              </p>
                            )}
                            {/* Answer Input */}
                            <div className="w-full">
                              {question.type === 'text' ||
                              question.type === 'number' ? (
                                embeddedSingleSelect ? (
                                  <EmbeddedSingleSelect
                                    question={question}
                                    options={options}
                                    control={control}
                                  />
                                ) : (
                                  <Input
                                    className="w-full border"
                                    {...register(question.key)}
                                    type={question.type}
                                    placeholder={question.placeholder}
                                  />
                                )
                              ) : question.type == 'textarea' ? (
                                <Textarea
                                  className="w-full whitespace-pre-wrap"
                                  {...register(question.key)}
                                  placeholder={question.placeholder}
                                />
                              ) : question.type === 'multi-select' ? (
                                <MultiSelectQuestion
                                  question={question}
                                  control={control}
                                  options={options}
                                  setFormValue={setFormValue}
                                  watch={watch}
                                />
                              ) : question.type ===
                                'single-select' ? (
                                <SingleSelectQuestion
                                  question={question}
                                  options={options}
                                  control={control}
                                  watch={watch}
                                  formValues={formValues}
                                  setFormValue={setFormValue}
                                />
                              ) : question.type === 'radio-group' ? (
                                <RadioGroupQuestion
                                  question={question}
                                  control={control}
                                  options={options}
                                />
                              ) : question.type ===
                                'emission-factor' ? (
                                <EmissionFactorQuestion
                                  question={question}
                                  control={control}
                                  formValues={formValues}
                                  setFormValue={setFormValue}
                                />
                              ) : question.type === 'value-unit' ? (
                                <ValueUnitQuestion
                                  question={question}
                                  control={control}
                                  formValues={formValues}
                                  setFormValue={setFormValue}
                                />
                              ) : question.type === 'ghg-category' ? (
                                <GhgCategoryQuestion
                                  question={question}
                                  control={control}
                                  formValues={formValues}
                                />
                              ) : question.type ===
                                'branch-identifier' ? (
                                <BranchIdentifierQuestion
                                  question={question}
                                  control={control}
                                />
                              ) : question.type === 'strategy-identifier' ? (
                                <StrategyIdentifierQuestion
                                  question={question}
                                  control={control}
                                />
                              ) : question.type === 'address' ? (
                                <>
                                  <GooglePlacesAutocomplete
                                    hookReturn={
                                      googlePlacesHookReturn
                                    }
                                    startAdornment={
                                      <MapPin className="h-5 w-5 text-primary" />
                                    }
                                    placeholder="Find address..."
                                    setPlace={(place) => {
                                      getGeocode({
                                        address: place.description
                                      }).then((results) => {
                                        const { lat, lng } =
                                          getLatLng(results[0]);

                                        if (setLatLng) {
                                          setLatLng({ lat, lng });
                                        }
                                      });
                                      getDetails({
                                        placeId: place.place_id
                                      }).then((results) => {
                                        const addressComponents =
                                          results?.address_components ||
                                          [];
                                        const city =
                                          addressComponents.find(
                                            (component) =>
                                              component.types.includes(
                                                'locality'
                                              )
                                          );
                                        const state =
                                          addressComponents.find(
                                            (component) =>
                                              component.types.includes(
                                                'administrative_area_level_1'
                                              )
                                          );
                                        const country =
                                          addressComponents.find(
                                            (component) =>
                                              component.types.includes(
                                                'country'
                                              )
                                          );
                                        const countryEnum =
                                          getAddressEnumValue(
                                            country?.long_name,
                                            'country'
                                          );
                                        const provinceEnum =
                                          countryEnum ==
                                          CountryNamesEnum.UnitedStates
                                            ? null
                                            : getAddressEnumValue(
                                                state?.long_name,
                                                'province'
                                              );
                                        const stateEnum =
                                          countryEnum ==
                                          CountryNamesEnum.Canada
                                            ? null
                                            : getAddressEnumValue(
                                                state?.long_name,
                                                'state'
                                              );

                                        const apt =
                                          addressComponents.find(
                                            (component) =>
                                              component.types.includes(
                                                'subpremise'
                                              )
                                          );

                                        setFormValue(
                                          'street',
                                          results.name
                                        );
                                        setFormValue(
                                          'city',
                                          city?.long_name
                                        );
                                        setFormValue(
                                          'state',
                                          countryEnum ==
                                            CountryNamesEnum.Canada
                                            ? provinceEnum
                                            : stateEnum
                                        );
                                        const countryName =
                                          getCountryEnumValue(
                                            country?.long_name
                                          );
                                        const formattedRegionName =
                                          state?.long_name
                                            .toUpperCase()
                                            .replace(' ', '_');
                                        const regionName =
                                          countryName &&
                                          countryName in
                                            COUNTRY_REGIONS
                                            ? Object.keys(
                                                COUNTRY_REGIONS[
                                                  countryName
                                                ]
                                              ).find(
                                                (value) =>
                                                  value ===
                                                  formattedRegionName
                                              ) || formattedRegionName
                                            : formattedRegionName;
                                        const zip =
                                          addressComponents.find(
                                            (component) =>
                                              component.types.includes(
                                                'postal_code'
                                              )
                                          );
                                        setFormValue(
                                          'apt',
                                          apt?.long_name
                                        );

                                        setFormValue(
                                          'street',
                                          results.name
                                        );
                                        setFormValue(
                                          'city',
                                          city?.long_name
                                        );
                                        setFormValue(
                                          'state',
                                          regionName
                                        );
                                        setSelectRegionOptions(
                                          countryName &&
                                            !(
                                              countryName in
                                              COUNTRY_REGIONS
                                            )
                                            ? [
                                                {
                                                  key: formattedRegionName,
                                                  label:
                                                    state?.long_name
                                                }
                                              ]
                                            : []
                                        );
                                        setFormValue(
                                          'country',
                                          countryName
                                        );
                                        setFormValue(
                                          'zip',
                                          zip?.long_name
                                        );
                                        setFormValue(
                                          'apt',
                                          apt?.long_name
                                        );
                                      });
                                    }}
                                  />
                                  {/* Address + Apt/Suite */}
                                  <div className="mt-md flex w-full">
                                    <div className="mr-md w-full">
                                      <AddressInputQuestion
                                        text={'Street'}
                                        questionKey="street"
                                        required={true}
                                        placeholder="E.g. 123 Store Street"
                                        errorMessage={
                                          errors?.['street']?.message
                                        }
                                      />
                                    </div>
                                    <div className="">
                                      <AddressInputQuestion
                                        text={'Apt/Suite'}
                                        required={false}
                                        errorMessage={
                                          errors?.['apt']?.message
                                        }
                                        placeholder="E.g. 101"
                                        questionKey="apt"
                                      />
                                    </div>
                                  </div>
                                  {/* Country */}
                                  <div className="flex w-full flex-row pb-sm pt-md">
                                    <div className="w-full">
                                      <AddressSingleSelectQuestion
                                        setFormValue={setFormValue}
                                        text="Country"
                                        required={true}
                                        questionKey="country"
                                        errorMessage={
                                          errors?.['country']?.message
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* City, State, Zip */}
                                  <div className="flex w-full flex-row pb-sm pt-md">
                                    <div className="mr-md w-full">
                                      {selectRegionOptions.length ||
                                      (formValues?.['country'] &&
                                        formValues?.['country'] in
                                          COUNTRY_REGIONS) ? (
                                        <AddressSingleSelectQuestion
                                          setFormValue={setFormValue}
                                          selectOptionsProp={
                                            selectRegionOptions
                                          }
                                          text={
                                            COUNTRY_REGIONS_NAME[
                                              formValues['country']
                                            ] ||
                                            'State/Province/Region'
                                          }
                                          required={true}
                                          // placeholder="E.g. Washington"
                                          errorMessage={
                                            errors?.['state']?.message
                                          }
                                          questionKey="state"
                                        />
                                      ) : (
                                        <AddressInputQuestion
                                          text={
                                            COUNTRY_REGIONS_NAME[
                                              formValues['country']
                                            ] ||
                                            'State/Province/Region'
                                          }
                                          required={true}
                                          placeholder="E.g. Washington"
                                          errorMessage={
                                            errors?.['state']?.message
                                          }
                                          questionKey="state"
                                        />
                                      )}
                                    </div>
                                    <div className="mr-md w-full">
                                      <AddressInputQuestion
                                        text={'City'}
                                        required={true}
                                        // placeholder="E.g. Seattle"
                                        placeholder={
                                          'E.g. ' +
                                          (COUNTRY_CAPITALS[
                                            formValues['country']
                                          ] || 'London')
                                        }
                                        errorMessage={
                                          errors?.['city']?.message
                                        }
                                        questionKey="city"
                                      />
                                    </div>
                                    <div className="w-full">
                                      <AddressInputQuestion
                                        text={
                                          formValues['country'] ==
                                          CountryNamesEnum.UnitedStates
                                            ? 'Zip Code'
                                            : 'Postal Code'
                                        }
                                        questionKey="zip"
                                        required={true}
                                        errorMessage={
                                          errors?.['zip']?.message
                                        }
                                        placeholder={
                                          formValues['country'] ==
                                          CountryNamesEnum.Canada
                                            ? 'E.g. V6B 1A7'
                                            : 'E.g. 12345'
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : question.type === 'icon' ? (
                                <Controller
                                  control={control}
                                  name={question.key}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      ref
                                    }
                                  }) => {
                                    return (
                                      <div className="mt-sm grid grid-cols-5 gap-4">
                                        {options.map(
                                          (option, idx) => {
                                            return (
                                              <Fragment
                                                key={getListItemKey(
                                                  idx
                                                )}
                                              >
                                                <div
                                                  ref={ref}
                                                  className={
                                                    'h-[121px] w-[121px] cursor-pointer rounded-md border transition-all ' +
                                                    'hover:border-primary/50 hover:ring-1 hover:ring-primary/50 ' +
                                                    (value ===
                                                    option.key
                                                      ? 'border-primary/50 shadow-md ring-1 ring-primary/50 '
                                                      : '') +
                                                    (!iconIndices.includes(
                                                      idx
                                                    )
                                                      ? 'hidden '
                                                      : '')
                                                  }
                                                  key={getListItemKey(
                                                    idx
                                                  )}
                                                  onClick={() => {
                                                    onChange(
                                                      value ===
                                                        option.key
                                                        ? ''
                                                        : option.key
                                                    );
                                                  }}
                                                  onBlur={onBlur}
                                                >
                                                  <img
                                                    src={option.key}
                                                    className={
                                                      'grayscale transition-all'
                                                    }
                                                    onLoad={() => {
                                                      setIconIndices(
                                                        (prev) => [
                                                          ...prev,
                                                          idx
                                                        ]
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                {!iconIndices.includes(
                                                  idx
                                                ) && (
                                                  <Skeleton
                                                    width={121}
                                                    height={121}
                                                  />
                                                )}
                                              </Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    );
                                  }}
                                />
                              ) : question.type === 'custom' ? (
                                <>
                                  <Controller
                                    control={control}
                                    name={question.key}
                                    render={
                                      question.renderCustomQuestion
                                    }
                                  />
                                  {question.required &&
                                  question.getErrorMessage ? (
                                    <p className="body2 mt-sm text-destructive">
                                      {question.getErrorMessage(
                                        formValues
                                      )}
                                    </p>
                                  ) : null}
                                </>
                              ) : question.type === 'date' ? (
                                <DatePickerQuestion
                                  question={question}
                                  control={control}
                                />
                              ) : null}
                            </div>
                            {/* Error */}
                            {errors[question.key] &&
                              Boolean(!isValid) && (
                                <p className="body2 mt-1 text-destructive">
                                  {
                                    errors[question.key]
                                      ?.message as string
                                  }
                                </p>
                              )}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            )
          );
        })}

        {/* Submit button */}
        <div className="mt-xl flex w-full flex-nowrap items-center justify-end">
          {Object.keys(errors || {}).length > 0 && (
            <div className="mr-md">
              <p className="text-destructive">
                Please complete all required fields.
              </p>
            </div>
          )}
          {submitToggleText && (
            <div className="mr-md flex flex-row items-center">
              <Switch
                checked={submitToggleChecked}
                onCheckedChange={setSubmitToggleChecked}
              />
              <p className="ml-md">{submitToggleText}</p>
            </div>
          )}
          <Button
            loading={isSubmitting}
            onClick={handleSubmit(handleSubmitWithProps)}
            disabled={submitButtonDisabled}
          >
            {submitButtonText || 'Submit'}
          </Button>
        </div>
      </div>
    </form>
  );
}
