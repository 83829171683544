import { gql, useQuery } from '@apollo/client';
import { StrategySelectorEntityQueryQuery } from 'src/__apolloGenerated__/graphql';
import SingleSelect from 'src/components/core/molecules/SingleSelect';

export default function StrategySelector({
  activeStrategyIdentifier,
  setActiveStrategyIdentifier,
  disabled = false,
  prefixText = 'Strategy: ',
  disableClear = false,
  loading,
  required = false,
  size = 'sm',
  type = 'default'
}: {
  activeStrategyIdentifier: string;
  setActiveStrategyIdentifier: (value: string) => void;
  disabled?: boolean;
  prefixText?: string;
  loading?: boolean;
  disableClear?: boolean;
  required?: boolean;
  size?: 'sm' | 'default';
  type?: 'default' | 'form';
}) {
  const { data: entityData, loading: entityLoading } =
    useQuery<StrategySelectorEntityQueryQuery>(
      gql`
        query StrategySelectorEntityQuery {
          activeEntity {
            data {
              target {
                strategies {
                  id
                  identifier
                  title
                }
              }
            }
          }
        }
      `,
      {
        skip: disabled,
        fetchPolicy: 'network-only'
      }
    );

  const strategies =
    entityData?.activeEntity?.data?.target?.strategies;

  return (
    <SingleSelect
      value={activeStrategyIdentifier}
      required={required}
      disabled={disabled}
      size={size}
      setValue={(value) => {
        setActiveStrategyIdentifier(value);
      }}
      loading={loading || entityLoading}
      placeholder="Select strategy..."
      commandEmptyText="No strategies found."
      options={strategies?.map((strategy) => ({
        key: strategy.identifier,
        label: strategy.title
      }))}
      prefixText={type === 'form' ? '' : prefixText}
      disableClear={disableClear}
    />
  );
}
